<template>
  <div>
    <!--  Suggest popup  -->
    <main-modal id="suggestPopup" size="md">
      <template v-slot:header>
        <div><h4 class="font-weight-bold text-initial">{{ $t('search.suggestProduct') }}</h4>
        <p>{{ $t('search.help') }}</p></div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="6" class="mb-3">
            <input v-model="suggestProduct.from" type="text"
                   class="form-control" :placeholder="$t('search.priceFrom')"/>
          </b-col>
          <b-col cols="6" class="mb-3">
            <input v-model="suggestProduct.to" type="text" class="form-control" :placeholder="$t('search.priceTo')"/>
          </b-col>
          <b-col cols="6">
            <main-select v-model="suggestProduct.category" :placeholder="$t('search.category')"
                         label="name" :reduce="data => data.id" :name="'categories'"
                         :options="categories" @change="getDependSubCategory">
            </main-select>
          </b-col>
          <b-col cols="6">
            <main-select v-model="suggestProduct.sub" :placeholder="$t('search.sub')"
                         label="subcat" :reduce="data => data.id" :name="'sub-categories'"
                         :options="subCategories">
            </main-select>
          </b-col>
          <b-col cols="6">
            <main-select v-model="suggestProduct.size" :placeholder="$t('search.size')"
                         :options="[]"></main-select>
          </b-col>
          <b-col cols="6">
            <main-select v-model="suggestProduct.branch" :placeholder="$t('search.branches')"
                         :options="[]"></main-select>
          </b-col>
          <b-col cols="12">
            <b-button class="curva-granola-btn" block @click="searchSuggestedProducts">
              {{ $t('search.search') }}
            </b-button>
          </b-col>
        </b-row>
<!--        <faqs-details @addFaqs="addFaqs"
                      @editFaqs="editFaqs"
                      :requestLoading="requestLoading"
                      :faqsDetails="faqsDetails"
                      :typeOfModal="typeOfModal"/>-->
      </template>
    </main-modal>

    <!--  Find product popup  -->
    <main-modal id="findProductPopup" size="md">
      <template v-slot:header>
        <div><h4 class="font-weight-bold text-initial">{{ $t('search.findProduct') }}</h4>
          <p>{{ $t('search.help') }}</p></div>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="12" class="mb-3 d-flex align-items-center">
            <input type="radio" v-model="findProduct.method" value="online" name="findProductMethod" id="radio1"
                   class="form-check form-check-inline" label="findProductMethod">
            <label for="radio1" class="px-2">{{ $t('search.online') }}</label>
            <input type="radio" v-model="findProduct.method" value="barcode" name="findProductMethod" id="radio2"
                   class="form-check form-check-inline mx-2">
            <label for="radio2" class="px-2">{{ $t('search.barcode') }}</label>
          </b-col>
          <b-col cols="12" class="mb-3">
            <input v-model="findProduct.code" type="text" class="form-control" :placeholder="$t('search.code')"/>
          </b-col>
          <b-col cols="12">
            <b-button class="curva-granola-btn" block @click="searchSuggestedProducts">
              {{ $t('search.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </main-modal>

    <!--  Side filter  -->
    <side-filter :categories="categories" :sideNav="sideFilter"
                 @filterData="getSearchList" @close="sideFilter = false" />

    <b-container class="iq-style1">
      <b-row class="py-4 d-flex justify-content-between">
        <b-col cols="12" lg="4">
          <b-row>
            <b-col cols="4" lg="3">
              <curva-title :title="$t('search.search')"></curva-title>
            </b-col>
            <b-col cols="8" lg="9" class="mb-3">
              <div class="headerInput m-0">
                <input type="text" class="form-control"
                       :placeholder="$t('search.search')" v-model="searchText"
                       @keyup.enter="$router.push({name: 'search', query: { q: searchText }})">
                <i class="fas fa-search"></i>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" cols="12" class="d-flex justify-content-center flex-wrap gap_2 mb-2">
          <router-link class="w-50" :to="{name: 'findProduct'}">
            <b-button class="w-100 curva-transparent-btn iq-border-radius-5">
              {{ $t('search.findProduct') }}</b-button>
          </router-link>
          <router-link class="w-40" :to="{name: 'suggestProduct'}">
            <b-button class="w-100 curva-transparent-btn iq-border-radius-5">
            {{ $t('search.suggest') }}</b-button>
          </router-link>
        </b-col>
        <b-col lg="4" cols="12" class="d-flex justify-content-center flex-wrap gap_2">
          <b-button class="w-50 curva-transparent-btn iq-border-radius-5" @click="sideFilter=true">
            {{ $t('search.filter') }}</b-button>
          <dropdown-btn class="w-40" @select="getSelected" />
        </b-col>
      </b-row>
    </b-container>
    <div id="offers-list" class="p-4 mb-5" v-if="resultProducts.length > 0">
      <b-container>
      <b-row>
        <b-col cols="12" class="text-center mb-4">({{ rows + ' ' + $t('search.results') }})</b-col>
        <b-col
            lg="3" sm="6"
            v-for="(product, key) in resultProducts"
            :key="key"
        >
          <product-card :product="product" />
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="d-flex justify-content-center">
          <b-pagination
              class="curva-pagination"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @input="getSearchList"
          ></b-pagination>
        </b-col>
      </b-row>
      </b-container>
    </div>
    <b-row v-else>
      <b-col cols="12">
        <iq-card><h5 class="p-2 text-center text-white">{{ $t('search.noProducts') }}</h5></iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import DropdownBtn from '@/components/core/dropdown/dropdown-btn'
import SearchServices from '@/modules/search/services/search.services'
import CategoriesServices from '@/modules/categories/services/categories.services'
import SideFilter from '@/modules/search/components/sideFilter'
export default {
  components: { DropdownBtn, SideFilter },
  data () {
    return {
      searchText: '',
      resultProducts: [],
      sortValue: 'id&dir=desc',
      perPage: 0,
      currentPage: 1,
      rows: 1,

      // suggest popup
      categories: [],
      subCategories: [],

      // find product popup
      // out obj
      suggestProduct: {
        from: '',
        to: '',
        category: '',
        sub: '',
        size: '',
        branch: ''
      },
      findProduct: {
        method: '',
        code: ''
      },

      sideFilter: false
    }
  },
  methods: {
    getSearchList () {
      SearchServices.getSeacrhList(this.searchText, this.currentPage, this.sortValue).then((res) => {
        window.scrollTo(0, 0)
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.currentPage = res.data.meta.current_page
        this.resultProducts = res.data.data
      })
    },
    getSelected (value) {
      this.currentPage = 1
      this.sortValue = value
      this.getSearchList()
    },
    openPopup (popupName) {
      if (popupName === 'suggest') {
        this.$bvModal.show('suggestPopup')
      } else {
        this.$bvModal.show('findProductPopup')
      }
    },
    getAllCategories () {
      CategoriesServices.getCategories().then(res => {
        this.categories = res.data.data
      })
    },
    getDependSubCategory () {
      this.suggestProduct.sub = ''
      this.subCategories = this.categories.find(category => category.id === this.suggestProduct.category).sub_category
    },
    searchSuggestedProducts () {
      console.log(this.suggestProduct)
    }
  },
  watch: {
    '$route.query' () {
      this.searchText = this.$route.query.q
      this.getSearchList()
      this.getAllCategories()
    }
  },
  created () {
    this.searchText = this.$route.query.q
    this.getSearchList()
    this.getAllCategories()
  },
  mounted () {
    core.index()
  }
}
</script>
