<template>
  <transition
      name="fade"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
  >
    <div v-show="sideNav" class="side-nav" @click.self="close()">
      <transition
          name="slide"
          enter-active-class="animate__animated animate__slideInRight animate__faster"
          leave-active-class="animate__animated animate__slideOutRight animate__faster"
      >
        <aside v-show="sideNav" class="side-filter">
          <div class="mobile-menu-close d-flex justify-content-end p-3 cursor-pointer">
            <i class="las la-times" @click="close()"></i>
          </div>
          <div class="side-filter-header d-flex align-items-end"
              :style="{ 'background-image': 'url(' + mode + ')' }">
            <div class="px-3 text-initial">
              <h4 class="font-weight-bold">{{ $t('search.filter') }}</h4>
              <p class="m-0">{{ $t('search.help') }}</p>
            </div>
          </div>
          <b-row class="p-3">
            <b-col cols="12">
              <main-select v-model="filter.category" :placeholder="$t('search.category')"
                           label="name" :reduce="data => data.id" :name="'categories'"
                           :options="categories" @change="getDependSubCategory">
              </main-select>
            </b-col>
            <b-col cols="12">
              <main-select v-model="filter.sub" :placeholder="$t('search.sub')"
                           label="subcat" :reduce="data => data.id" :name="'sub-cat'"
                           :options="subCategories">
              </main-select>
            </b-col>
            <b-col cols="12">
              <main-select v-model="filter.brand" :placeholder="$t('search.brand')"
                           label="name" :reduce="data => data.id" :name="'brands'"
                           :options="allBrands">
              </main-select>
            </b-col>
            <b-col cols="12">
              <main-select v-model="filter.club" :placeholder="$t('search.club')"
                           label="name" :reduce="data => data.id" :name="'clubs'"
                           :options="allClubs">
              </main-select>
            </b-col>
            <b-col cols="12" class="mb-3">
              <input v-model="filter.pricefrom" type="number" class="form-control mb-3"
                     :placeholder="$t('search.priceFrom')"/>
              <input v-model="filter.priceto" type="number" class="form-control"
                     :placeholder="$t('search.priceTo')"/>
            </b-col>
            <b-col cols="12">
              <b-button class="curva-granola-btn" block @click="sendFilter()">{{ $t('search.search') }}</b-button>
            </b-col>
          </b-row>
        </aside>
      </transition>
    </div>
  </transition>
</template>

<script>
import aboutServices from '@/modules/about/services/about.services'

export default {
  props: ['categories', 'sideNav'],
  data () {
    return {
      filterImageDark: require('@/assets/images/curva/filterImage_dark.png'),
      filterImageLight: require('@/assets/images/curva/filterImage_dark.png'),
      mode: '',
      filter: {
        category: '',
        sub: '',
        brand: '',
        club: '',
        size: '',
        season: ''
      },
      subCategories: [],
      allBrands: [],
      allClubs: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    sendFilter () {
      this.$emit('filterData', this.filter)
    },
    getDependSubCategory () {
      console.log(this.categories)
      this.filter.sub = ''
      this.subCategories = this.categories.find(category => category.id === this.filter.category).sub_category
    },
    getAllClubs () {
      aboutServices.getAllClubs().then(res => {
        this.allClubs = res.data.data
      })
    },
    getAllBrands () {
      aboutServices.getAllBrands().then(res => {
        this.allBrands = res.data.data
      })
    }
  },
  created () {
    this.getAllClubs()
    this.getAllBrands()
  },
  mounted () {
    this.mode = localStorage.getItem('mode') === 'dark' ? this.filterImageDark : this.filterImageLight
  }
}
</script>
<style>
.side-filter-header {
  position: relative;
  width: 300px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media only screen and (max-width: 500px) {
  .side-filter-header {
    width: 300px;
    height: 150px;
  }
}
side-filter-header::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(40, 90, 5, 1);
}
</style>
